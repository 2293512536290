.ml-2 {
    margin-left: .5rem !important
}

a,
.nav-link {
    color: rgb(72, 185, 162);
}

.btn-outline-success {
    color: rgb(72, 185, 162);
}

.form-check-input:checked {
    background-color: rgb(72, 185, 162, 0.5);
    border-color: rgb(72, 185, 162, 0.5);
}