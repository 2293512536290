.model-card input {
  border: none;
  background: none;
}
:root {
  --width_phone_template: 60%;
}

.flow_container {
  margin: 0;
  padding: 0;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.flow_leftBlock {
  /* background-color: #fff; */
  /* padding: 30px; */
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75); */
}

.flow_leftBlock.mainScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flow_rightBlock {
  /* padding: 30px; */
  /* height: calc(100vh - 100px); */
  overflow: auto;
  position: relative;
}

.flow_rightBlock .exit {
  margin: 0 auto;
  text-align: center;
  padding: 5px;
  width: 40px;
  color: #bac2ca;
  border: 1px solid #bac2ca;
}

.models_list {
  max-height: 500px;
  overflow: auto;
}

.action_block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  cursor: move;
  font-size: 13px;
}
.action_block:hover {
  box-shadow: 0px 0px 7px 0px rgba(249, 249, 249, 0.75);
}

.flow_block {
  margin: 0 auto;
  border-radius: 5px;
  width: 300px;
  padding: 10px 10px;
  background-color: #d5e2ee;
  cursor: move;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.75);
}
.flow_block_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flow_block_header p {
  margin: 0;
  padding: 0;
}
.flow_block_status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flow_block_status .status {
  margin: 0;
  padding: 0;
  background-color: #a9ada9;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: bold;
}

.flow_block_status .status_data_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flow_block_status .status_data_list i {
  font-size: 14px;
  margin-left: 5px;
}

.line_flow {
  margin: 0 auto;
  position: relative;
  width: 300px;
}
.line_flow .line_top,
.line_flow .line_bottom {
  margin: 0 auto;
  width: 2px;
  background-color: #bac2ca;
  height: 25px;
}

.line_flow .middle {
  margin: 0 auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #bac2ca;
  position: relative;
}

.line_flow .middle.active::before {
  position: absolute;
  content: "+";
  color: #fff;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #22be22;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.line_flow .middle.dragOver {
  width: 100%;
  background-color: #ebeeef;
  border: 1px dotted #bac2ca;
  border-radius: 5px;
  height: 70px;
}

.editBlock {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editBlock .contentEdit {
  border-bottom: 2px solid #a7a4a4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.editBlock .info {
  display: flex;
  /* padding-top: 10px; */
}

.editBlock .info p {
  color: #bfb3b3;
}

.editBlock .settings {
  border-bottom: 2px solid #a7a4a4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.editBlock .settings .setting_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
}
.editBlock .settings .setting_item p {
  margin: 0;
  padding: 0;
  margin-left: 20px;
}
.editBlock .buttons {
  width: 100%;
  background-color: #ebebeb;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}
.editBlock .buttons button {
  width: 100%;
  background-color: #fff;
  padding: 5px;
}
.editBlock .buttons button:hover {
  background-color: #ebebeb;
}
.flows_create_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
}

.ItemFlowCreateCard {
  margin: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 300px;
  padding: 15px;
}
.ItemFlowCreateCard:hover {
  box-shadow: 0px 0px 10px 0px rgba(40, 51, 172, 0.75);
}

.ItemFlowCreateCard h2 {
  font-size: 14px;
}
.ItemFlowCreateCard h3 {
  font-size: 16px;
  font-weight: bold;
}
.ItemFlowCreateCard p {
  margin: 0;
  padding: 0;
}

.modal_create_left {
  background-color: #fff;
}

.modal_create_right {
  background-color: #e7e0e0;
}

.block_timeline {
  background-color: #fff;
  padding: 10px;
}
.block_timeline .header {
  background-color: #f3efef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.block_timeline .header p {
  margin: 0;
  padding: 0;
  margin-right: 20px;
}
.block_timeline .header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
}
.block_timeline .header ul li {
  padding: 2px 7px;
  margin: 3px;
  border-radius: 5px;
}
.block_timeline .header ul li i {
  color: #fff;
  font-size: 16px;
}
.block_timeline .header ul li:nth-child(1) {
  background-color: #db554c;
}
.block_timeline .header ul li:nth-child(2) {
  background-color: #4188ca;
}
.block_timeline .header ul li:nth-child(3) {
  background-color: #68b162;
}
.block_timeline .header ul li:nth-child(4) {
  background-color: #eaaf55;
}
.block_timeline .header ul li:nth-child(5) {
  background-color: #dbdddc;
}

.content_timeline {
  position: relative;
}

.content_timeline .line_tree {
  position: absolute;
  z-index: 1;
  width: 3px;
  background-color: #e7e1e1;
  top: 0;
  left: 40px;
  height: 100%;
}
.content_timeline .tree {
  background-color: transparent;
  padding: 15px 0;
  position: relative;
  z-index: 2;
}
.content_timeline .tree .months {
  background-color: transparent;
  list-style: none;
  margin: 0;
  padding: 0;
}
.content_timeline .tree .months li {
  background-color: transparent;
}
.content_timeline .tree .months li h3 {
  background-color: #91928c;
  display: inline;
  font-size: 12px;
  color: #fff;
  padding: 2px 20px;
}
.content_timeline .tree .months li .activities {
  background-color: transparent;
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
}
.content_timeline .tree .months li .activities li {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}
.content_timeline .tree .months li .activities li .icon {
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
}
.content_timeline .tree .months li .activities li .icon i {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
}

.content_timeline .tree .months li .activities li .data {
  box-shadow: 0px 0px 10px 0px rgba(40, 51, 172, 0.75);
  padding: 10px 10px 10px 20px;
  margin-left: 15px;
  position: relative;
}
.content_timeline .tree .months li .activities li .data::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
  left: 0;
  top: 20px;
  box-shadow: -1px 1px 1px -1px rgba(40, 51, 172, 0.75);
}
.content_timeline .tree .months li .activities li .data h2 {
  color: #db554c;
  font-size: 12px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}
.content_timeline .tree .months li .activities li .data h3 {
  color: #728c9d;
  font-size: 12px;
  background-color: transparent;
  margin: 0;
  padding: 0;
}
.content_timeline .tree .months li .activities li .data h3 i {
  color: #728c9d;
  margin-right: 5px;
}
.content_timeline .tree .months li .activities li .data h4 {
  color: #000;
  font-size: 10px;
  background-color: transparent;
  margin: 0;
  padding: 0;
  margin-top: 5px;
}

.svg_container {
  max-width: 200px;
  overflow: hidden;
  width: 200px;
  fill: #000;
  stroke: #000;
  background: #000;
}

.svg_rect {
  fill: rgb(88, 88, 88);
  stroke: #fff;
background: #000;
}
.svg_name {
  fill: #b1b1b1;
  stroke: #b1b1b1;
  font: italic 16px sans-serif;
  letter-spacing: 2px;
}
.svg_title {
  fill: #b1b1b1;
  stroke: #b1b1b1;
  letter-spacing: 2px;
  font-size: 14px;
  max-width: 200px;
  overflow: auto;
}


.rd3t-link.node__branch {
  stroke: #fff;
}
