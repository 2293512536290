.userForm {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 480px) {
  .userForm {
    gap: 10px;
    grid-template-columns: repeat(1, 1fr);
  }
}
